@import (less) "~animate.css/animate.css";

.SendInnPopin {
  max-width: 50rem;

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-flexInput {
    flex: 1 1 50%;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }

    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-radioWrapper {
    margin-top: 0.5rem;
    display: block;
  }

  select,
  input {
    .animate__animated;

    animation-duration: 0.5s;
    animation-name: pulse;
  }

  &-checkbox {
    margin-bottom: 1rem;
  }

  [type="radio"] &-select {
    select {
      text-indent: 0.5rem;
      background: var(--white);
    }
  }

  &-input,
  &-select {
    display: block;
    margin-top: 0.5rem;
  }

  &-label {
    font-weight: 700;
    font-size: 1rem;
    padding-top: 2rem;
    display: block;
  }

  &-message {
    margin-top: 1rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 3.5rem;
    background: var(--offwhite);
    text-indent: -2rem;

    &:first-child {
      margin-top: 4rem;
    }
  }
}

@media @small {
  .SendInnPopin {
    &-wrapper {
      flex-wrap: wrap;
    }

    &-flexInput {
      flex-basis: 100%;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
}
