@import (inline) "@utdanningsdirektoratet/grid/Grid.module.less";

.Grid {
  width: 100%;
  padding: 0 0 1rem 0;
  margin: 0 auto;
  position: relative;
  left: 0;
  max-width: 70rem;
}

@media @large {
  .Grid {
    display: flex;
    flex-direction: row;

    &--innsendt {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--offset {
      max-width: 86rem;
    }
  }
}

@media @extra-large {
  .Grid {
    &--offset {
      max-width: 89rem;
    }
  }
}
