.Obligatorisk {
  display: inline-flex;
  gap: 2rem;

  &-icon {
    align-self: center;
  }
}

@media print {
  .Obligatorisk-icon {
    display: none;
  }
}
