.Fotnoter {
  margin: 4rem 0;

  &-title {
    margin-bottom: 1rem;
  }

  &-list {
    overflow-wrap: break-word;
  }
}
