.Vedlegg {
  &--error {
    background: var(--peach6) !important;
  }
}

@media @medium {
  .Vedlegg {
    &--uploaded {
      width: 25rem;
    }
  }
}
