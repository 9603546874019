.Sidebar {
  margin: 1rem 2rem 1rem 1rem;

  &-wrapper {
    background: var(--white);

    &--faded {
      margin-top: 3rem;
      opacity: 0.9;
    }
  }

  &-item {
    position: relative;
    border-bottom: 0.0625rem solid var(--lightgrey);
    transition: all linear 0.1s;

    &:last-child {
      border-bottom: none;
    }

    &-link {
      display: block;
      padding: 1rem 2.5rem 1rem 1rem;
      background-image: none;
      transition: all linear 0.1s;
      font-weight: 500;
      z-index: 2;
      color: var(--secondary);

      &:hover,
      &:active,
      &:focus {
        background: var(--offwhite);
        color: var(--darkgrey);
      }
    }

    &-text {
      display: block;
      padding: 1rem 2.5rem 1rem 1rem;
      transition: all linear 0.1s;
      font-weight: 500;
      position: relative;
    }

    &-label {
      font-weight: 400;
      font-size: 0.75rem;
      color: var(--darkgrey);
    }

    &-icon {
      opacity: 0;
      position: absolute;
      top: 40%;
      transition: all linear 0.1s;
      visibility: hidden;
      transform: translateY(-50%) rotate(90deg);
      z-index: 2;

      path,
      g {
        fill: var(--white);
      }
    }

    &-question {
      padding: 0;
      margin: 0;
      list-style: none;

      &-icon {
        position: absolute;
        top: 1.25rem;
        left: 1rem;
      }

      &-text {
        margin-left: 2rem;
        word-break: break-word;
      }
    }

    &-edit {
      &-textarea {
        padding-top: 0;

        textarea {
          color: var(--secondary-text);
          background: var(--secondary);
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          padding-right: 2.5rem;
          border: 0;
          min-height: 1rem;
        }
      }

      &-buttons {
        background: var(--white);
        padding-top: 0.5rem;
      }
    }

    &--selected {
      color: var(--white);
      background: var(--activeblue);

      &:hover,
      &:active,
      &:focus {
        background: var(--activeblue);
        color: var(--white);
      }
    }

    &--selected &-question-icon path {
      fill: var(--white);
    }

    &--selected &-label {
      color: var(--lightgrey);
    }

    &--selected &-icon {
      opacity: 1;
      right: 1rem;
      top: 50%;
      visibility: visible;
    }

    &--selected &-link {
      color: var(--secondary-text);
      background: var(--secondary);
    }

    &--faded {
      opacity: 0.3;
    }
  }

  &-timestamp {
    height: 0;
    padding: 0 1rem;
    color: var(--white);
    font-size: 0.8rem;
    background: var(--darkgrey);
    overflow: hidden;
    transition: all linear 0.2s;

    &--visible {
      height: auto;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
}

@media @large {
  .Sidebar {
    flex: 0 0 14rem;
    margin-top: 21rem;

    &-item {
      &-icon {
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media @extra-large {
  .Sidebar {
    flex: 0 0 17rem;
  }
}
