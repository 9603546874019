@import (reference) "@utdanningsdirektoratet/styles/mixins";

.Seksjon {
  position: relative;
  transition: all linear 0.1s;

  &-title {
    padding-top: 1rem;
    margin-bottom: 1rem;

    &.u-h1 select,
    &.u-h1 input {
      .h1();
    }

    &.u-h2 select,
    &.u-h2 input {
      .h2();
    }

    &.u-h3 select,
    &.u-h3 input {
      .h3();
    }
  }

  &-edit {
    position: relative;
    transition: all linear 0.1s;
    background: var(--white);

    &--active {
      margin: 0 -2rem;
      padding: 1rem 3rem 2rem 3rem;
      border-top: 0.125rem solid var(--secondary);
      box-shadow: 0 1.5625rem 2.5rem -1.25rem var(--darkgrey50);
      z-index: 1;
    }

    &-wrapper {
      display: flex;
      padding: 0 1rem;
    }

    &-level {
      flex: 0 0 auto;

      select {
        height: 2.625rem;

        & + i {
          top: 0.75rem;
        }
      }
    }

    &-title {
      flex: 1 1 auto;
      margin: 0 1rem;

      input {
        height: 2.625rem;

        & + i {
          bottom: 1rem;
        }
      }
    }

    &-reorder {
      flex: 0 0 auto;

      button {
        padding: 0 0.75rem;
      }
    }

    &-new {
      position: relative;
      cursor: pointer;
      height: 5rem;
      transition: all linear 0.1s;

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 0;
        top: 50%;
        transform: translateY(-50%);
        background: var(--lightgrey);
        transition: all linear 0.1s;
      }

      &:hover&:after {
        height: 1rem;
      }
    }

    &-dropdown {
      float: right;

      div ul li button {
        max-width: 100%;
      }
    }
  }

  .u-h2 {
    margin-top: 2rem;
  }

  .fotnote {
    font-size: 75%;
    font-weight: bold;
    vertical-align: baseline;
    position: relative;
    top: -0.4rem;
    cursor: pointer;
    background-image: none;

    &:before {
      content: "[";
    }

    &:after {
      content: "]";
    }
  }

  sup [data-fotnote-id] {
    font-weight: bold;
    cursor: pointer;
    background-image: none;

    &:before {
      content: "[";
    }

    &:after {
      content: "]";
    }
  }

  .table-wrapper {
    display: block;
    overflow: auto;

    table {
      width: auto;
      margin-bottom: 2rem;
    }
  }
}
