.SvarAlternativ {
  display: flex;

  &--obligatory {
    padding-right: 1.5rem;
  }

  &-input-field input {
    background: var(--white);
    line-height: 2.5rem;
    min-height: 2.5rem;
    padding-right: 0.5rem;
    text-indent: 0.5em;
  }

  &-input-field i {
    bottom: 0.75rem;
  }

  &-input {
    margin-top: 0.5rem;

    &--egendefinert {
      flex: 0 6.25rem;
    }
  }

  &-wrapper {
    padding: 0 0 0 1rem;
    flex: 1 1 auto;

    input {
      height: 2.5rem;
    }
  }

  &-wrapper-input--egendefinert input {
    background-color: var(--white);
  }

  &-edit {
    padding: 0.5rem 0;
  }

  &-edit &-wrapper {
    padding: 0;
    margin-right: 1rem;
    flex: 1 1 auto;
  }

  &-edit &-input {
    flex: 0 0 auto;
  }

  &-edit &-input,
  &-custom &-input {
    margin-top: 0.5rem;
    font-weight: 400 !important;
  }

  &-add {
    margin-top: 0.5rem;
    transition: all linear 0.1s;
  }

  &-custom &-wrapper {
    padding-left: 1rem;
  }

  &-icon {
    margin-left: 1rem;
  }

  &-button {
    flex: 0 0 auto;
    padding: 0 0.75rem;
  }

  &-reorder {
    flex: 0 0 auto;
    margin-right: 1rem;
  }

  &-type {
    margin-top: 2rem;
  }

  &-edit &-checkbox,
  &-edit &-radio {
    margin: 0.25rem 0 0 0 !important;
  }
}

@media @medium {
  .SvarAlternativ {
    display: flex;

    &--obligatory {
      padding-right: 3rem;
    }
  }
}
