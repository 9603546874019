:root {
  --highlight: #f8e71c;
  --highlight50: #f8e71c5f;
  --highlight-hover: #dfce03;
}

.MarkerKommentar {
  position: relative;
  padding: 2rem 0.5rem;
  margin: 0 -0.5rem 1.5rem;
  transition: all linear 0.1s;
  border-bottom: 0.5rem solid var(--secondary);

  &--active {
    padding: 2rem 1rem;
    margin: 0 -1rem 1.5rem;
    box-shadow: 0 -0.5rem 0 1rem var(--secondary);
  }

  &-content {
    outline: none;
  }

  &-comment {
    cursor: pointer;
    background: var(--highlight);
    color: var(--darkgrey);

    &:hover {
      background: var(--highlight-hover);
    }
  }

  img&-comment {
    outline: var(--highlight50) solid 1rem;
    outline-offset: -0.5rem;
  }

  img {
    cursor: pointer;
  }

  &-info {
    position: relative;
    background: var(--secondary);
    color: var(--secondary-text);
    padding: 5rem 0.5rem 1.5rem 0.5rem;
    margin: 0 -0.5rem;
    transition: all linear 0.1s;

    &-wrapper {
      position: relative;

      &:after {
        content: "";
        z-index: 10;
        border: 0.5rem solid transparent;
        background: transparent;
        border-top-color: var(--secondary);
        position: absolute;
        pointer-events: none;
        left: 0;
        bottom: -2.625rem;
        transition: none;
      }
    }

    &--active {
      margin: 0 -2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &-edit {
      margin: 0 0 0.125rem 0;
    }

    &-edit &-wrapper {
      &:after {
        display: none;
      }
    }

    .highlight {
      background: var(--highlight);
      color: var(--darkgrey);
    }

    &-tooltip {
      position: absolute;
      top: -3rem;
      left: 3rem;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      text-align: left;
      width: 12rem;
      color: var(--bordergrey);
      background-color: var(--darkgrey50);

      &-inner {
        display: block;
        position: relative;

        &:after {
          content: "";
          z-index: 10;
          border: 0.5rem solid transparent;
          background: transparent;
          margin-left: -0.5rem;
          border-top-color: var(--darkgrey50);
          position: absolute;
          pointer-events: none;
          left: 50%;
          bottom: -1.5rem;
        }
      }
    }
  }

  &-tooltip {
    position: relative;
    display: inline-block;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    z-index: 10;
    padding: 0;
    padding-right: 1.5rem;
    background-color: var(--darkgrey50);
    font-size: 1rem;
    text-align: left;
    width: 100%;
    max-width: 20rem;
    overflow-wrap: break-word;
    visibility: visible;
    opacity: 1;

    &:after {
      content: "";
      z-index: 10;
      border: 0.5rem solid transparent;
      background: transparent;
      margin-left: -0.5rem;
      border-top-color: var(--darkgrey50);
      position: absolute;
      pointer-events: none;
      left: 50%;
      bottom: -1rem;
    }

    &--mobile {
      &:after {
        margin-left: -0.5rem;
        border-top-color: transparent;
        border-bottom-color: var(--darkgrey50);
        top: -1rem;
        bottom: auto;
      }
    }
  }

  &-textarea {
    overflow: auto;
    min-height: auto;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: 20rem;
    background: transparent !important;
    border: none;
    resize: none;
    color: var(--bordergrey);
    outline: none;

    &--active {
      color: var(--white);
    }
  }

  &-button {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    outline: none;

    path,
    g {
      fill: var(--white);
    }

    &:hover {
      path,
      g {
        fill: var(--bordergrey);
      }
    }
  }

  &-addcomment {
    opacity: 0;
    background: var(--darkgrey) !important;
    color: var(--white) !important;
    border: none;
    border-radius: none;
    z-index: 30;
    transition: all 0.25s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    position: fixed;
    bottom: -4rem;
    height: 3.5rem;
    font-size: 1.25rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    text-align: center;
    white-space: nowrap;
    box-shadow: 0 1.5625rem 1.5rem -1.25rem var(--darkgrey50);

    &:active {
      transform: translate3d(-50%, 0, 0);
    }

    &--visible {
      opacity: 1;
      bottom: 15%;
    }

    path,
    g {
      fill: var(--white);
    }
  }
}

@media @medium {
  .MarkerKommentar {
    padding: 2rem;
    margin: 0 -2rem 1.5rem;

    &-info {
      padding: 5rem 2rem 1.5rem 2rem;
      margin: 0 -2rem;

      &--active {
        margin: 0 -3rem;
        padding-left: 3rem;
        padding-right: 3rem;
      }

      &-edit&--active {
        padding: 5rem 2rem 1.5rem 2rem;
        margin: 0 -2rem;
      }
    }

    &-addcomment {
      display: none;
    }
  }
}
