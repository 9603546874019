.FooterButtons {
  &-button {
    display: block;
    margin-top: 1rem;
  }
}

@media @medium {
  .FooterButtons {
    &-button {
      display: inline-block;
    }

    &-submit {
      display: block;
    }

    &-next {
      vertical-align: bottom;
      text-align: right;
    }
  }
}

// Hack for akkurat mellomrommet mellom 1440px og 1489px
@media only screen and (min-width: 1440px) and (max-width: 1489px) {
  .FooterButtons {
    &-button {
      margin-top: 1rem !important;
    }

    &-submit {
      display: block !important;
    }

    &-next {
      vertical-align: bottom !important;
    }
  }
}

@media @extra-large {
  .FooterButtons {
    &-button {
      margin-top: 0;
    }

    &-submit {
      display: inline-block;
    }

    &-next {
      vertical-align: inherit;
      text-align: right;
    }
  }
}
